import './App.css';
import React, { useEffect, useState } from "react";
function App() {
  // Chrome 1 - 71
  const isChrome = !!window.chrome;
  const [isMobile, setMobile] = useState(window.innerWidth <= 600);

  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 500);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  console.log(isMobile);

  return (
    <div className="App">
      <div className='container'>
        <div className='title-art'>
          <img src="./images/Slides_n_Ladders@1x.png" alt="Slides and ladder title art" />
        </div>

        {(!isChrome || isMobile) && 
          <div className='error-wrapper'>
            <p className='error-title'>We run on Chromium technology</p>
            <p className='error-desc'> Sorry Slides & Ladders currently run only on chrome browser. </p>
            <a href='https://www.google.com/chrome/'><button className='error-btn'> Get Chrome</button></a>
          </div>
        }

        {isChrome &&
          <div className='play-wrapper'>
            <a href='https://play-fog.rundownstudios.com/'> <button className='play-btn'>Play Now</button></a>
          </div>
        }

        <div className='bottom-wrapper'>
          <img className='rds-logo' src="./images/RDS_Log@1x.png" alt="Slides and ladder title art" />
          <p className='game-desc'>Slides & Ladders is a game developed and designed by Rundownstudios and funded by Revox Private Limited.
            Certain product names used within the context of this game may be trademarks or registered trademarks of Foundation of Goodness and/or
            one of its subsidiaries or affiliates in Sri Lanka and/or other countries.
            Please refer to the "Trademarks" section for appropriate markings and acknowledgments.
          </p>
          <img className='rvx-logo' src="./images/RVX_Logo@1x.png" alt="Slides and ladder title art" />

        </div>
      </div>
    </div >
  );
}

export default App;
